export class Vente {
  id: number;
  nature?: string;
  qty?: number;
  prixttc?: number;
  totalttc?: number;
  numero?: string;
  souche?: string;
  code_article_generic?: string;
  code_a_barre?: string;
  datecreation?: string ;
  gl_numero?: number;
  gl_typeprovenance?: string;
  fournisseur ? : string ;
  payment ? : string ; 
  selected? : boolean = false ;
  editMode? : boolean = false ;
  stock? : string ;

  constructor(id: number, attributes: any) {
    this.id = id;
    this.nature = attributes.nature;
    this.qty = attributes.qty;
    this.prixttc = parseFloat(attributes.prixttc.toFixed(2)); //two number after , ..
    this.totalttc = parseFloat(attributes.totalttc.toFixed(2));
    this.numero = attributes.numero;
    this.souche = attributes.souche;
    this.code_article_generic = attributes.code_article_generic;
    this.datecreation = attributes.datecreation;
    this.code_a_barre = attributes.code_a_barre;
    this.gl_numero = attributes.gl_numero;
    this.gl_typeprovenance = attributes.gl_typeprovenance;
    this.fournisseur = attributes.fournisseur;
    this.payment = attributes.payment ;
  }

  static mapVentes(data: any[]): Vente[] {
    return data.map((item, index) => new Vente(item.id, item.attributes));
  }
  static UpdateVent(Vente : Vente): Vente {
    Vente.selected = null ;
    Vente.editMode = null ;
    return Vente;
  }

}


