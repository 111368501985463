import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
			<div class="logo-text">
				<img src="assets/layout/images/devstyl.jpg" />
				<div class="text">
					<!-- <span>Premium Application Template</span> -->
				</div>
			</div>
			
        </div>
    `
})
export class AppFooterComponent {

}
