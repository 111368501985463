import { Component, OnInit } from '@angular/core';
import {GetFromStrapiService} from '../../Services/GetStrapi/GetFromStrapi.service' ;
import {RANDOMAPIService} from '../../Services/RandomAPI/RANDOMAPI.service' ;
import { Societe } from '../../Models/Societe';
import { Code } from '../../Models/Generated';
import { Store } from '../../Models/Societe';
import { Societes } from '../../Models/Generated';
import {MessageService} from 'primeng/api';
import { Achat } from '../../Models/Achats';
import { repeat } from 'rxjs';

@Component({
  selector: 'app-distribution-dialog-achat',
  templateUrl: './distribution-dialog-achat.component.html',
  styleUrls: ['./distribution-dialog-achat.component.scss']
})
export class DistributionDialogAchatComponent implements OnInit {

  visible = false
  selectedstore
  loading: boolean;
  ids: number[];

  constructor(private messageService: MessageService,private Strapi : GetFromStrapiService , private random : RANDOMAPIService) { }

  async ngOnInit() {
    await this.getsociete()
    const foundIndex = this.Societes.findIndex(societe => societe.stores.findIndex(store => store.name === this.DefaultStore));
    console.log('index is ' , foundIndex)
    this.selectedSocietes = [this.Societes[foundIndex]]
    this.generateSociete()
  }

    // --------------------------- Parametres de societes -------------//

    async getsociete(){
      try {
       const data = await this.Strapi.getFromStrapi("societes" ,1,25,null,null,null,null,null,null,'populate=icon');
       this.Societes = Societe.mapSocietes(data);
       this.Societes.forEach( async (Societe1) => {
         const url = Societe1.api_url
         const data =  await this.random.getUrl(`https://${url}stores?`)
         Societe1.stores = Store.mapSocietes(data)
       });
     } catch (error) {
       console.error('Error fetching ventes:', error);
     } finally {
       
     }
    }

    async generateSociete() {
      this.loading = true;
      this.Ste = [];
      this.selectedSocietes.forEach(async (societe)=>{
        let listcode : Code [] = [];
        for (let code of this.barcodeA) {
          let check = true;
          const url = societe.api_url;
      
          try {
            const data = await this.random.getUrl(`https://${url}products?filters[$and][0][code_article_generique][$eq]=${code.code_article}`);
            check = data.length > 0;
          } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error as needed
          }
      
          const code1 = new Code(code.code_article, code.prix_ht, 0, code.quantity, check);
          listcode.push(code1);
        }
        console.log('societe:', societe);
          console.log('societe.stores:', societe.stores);
          // const storeindex = societe.stores.findIndex(store => store.name === this.DefaultStore)
          // console.log('la index est = ', storeindex)
          // this.selectedstore = societe.stores[storeindex]
          const societe1 = new Societes(societe.id,societe.name,listcode ,this.DefaultStore )
          console.log('la societe est = ',societe1)
          this.Ste.push(societe1)
          this.loading = false;
      })
     }


     //----------------------------- parameteres de selection ------------------------//
     DeleteCode(code: Code){
      
      this.Ste.forEach(societe => societe.codes.splice(this.Ste[0].codes.indexOf(code),1) )
     }
     onRowSelect(event) {
      this.generateSociete()
    }

  
    onStoreSelect(event,societe){
      this.DefaultStore = event.data.name
     const find = this.selectedSocietes.filter((ste) => ste.id === societe.id ).length > 0
      if (find) {
        this.Ste.forEach((soc)=>{
          if(soc.name === societe.name){
            soc.store = event.data.code
          }
        })
      } else {
  
      }
    }
    onRowUnselect(event) {
      this.generateSociete()
    }

    // totoalmax(ri): number {
    //   let total = 0;
    
    //   // Calculate the total quantity for all Ste
    //   this.Ste.forEach((s) => {
    //     total += s.codes[ri].qty;
    //   });
    
    //   // Calculate the maximum quantity based on the first Ste
    //   const maxQty = Math.max(0, this.Ste[0].codes[ri].qtytotal - total);
    
    //   // Ensure maxQty is not negative or less than zero
    //   return maxQty;
    // }
   
    

    // --------------------------- Parametres d'envoi ------------------------//
    check():void {
      console.log(this.Ste)
      if(this.Ste.length === 0){
        this.messageService.add({severity:'error', summary:'Groupe Vide', detail:'Selectionner un groupe'});
        setTimeout(() => { 
          this.messageService.clear();
        },2000);
      }
      let data: any;
      let senddata: any[] = []; // Initialize senddata as an empty array
      this.Ste.forEach(code => {
        console.log('code1: ', code);
        const filteredCodes = code.codes.filter(code => code.avaible).map(({ avaible, ...rest }) => rest);
        data = {
          "id": code.id,
          "ids" : this.ids,
          "fournisseur": this.barcodeA[0].fournisseur,
          "name": code.name,
          "codes": filteredCodes,
          "store": code.store
        };
        senddata.push(data);
      });
        console.log("Modified Data:", senddata);
    this.Strapi.postStrapi('manageAchat',senddata) 
    this.visible = false;
    this.messageService.add({
    severity: 'success',  summary: 'Validation réussie',
    detail: 'Envoi avec succès'})
    }
    canclick():boolean{
      const index =   this.Ste.find(societe => societe.codes.find(code => code.avaible === false))
        if(index){
           return false
        }else{
          return true
        }
      }

    //----------------------------- show & close ------------------------//

    DefaultStore
    barcodeA : Achat[] = []; // Pour les Code depuis le tableau 
    Societes: Societe[] = []; // Pour les societes depuis Strapi 
    selectedSocietes: Societe[] = []; // pour les Societe selectionner 
    Ste :Societes [] = []; // la liste des societes avec des codes articles a afficher sure le tableau 
    type : string 
    showdialog( achat? : Achat[] , ids?): void { 
      this.ids = ids;

      this.selectedSocietes = [] 
      this.Ste = [] 
      this.barcodeA = [] 
      this.Societes = [] 
      this.visible = true ; 
      if(achat) { this.type = 'achat' ; this.barcodeA = achat ; this.DefaultStore = 'AZURE1'} 
      this.ngOnInit()
    }
    close(): void {
      this.visible = false;
    }
}
