export class Societe {

  id: number;
  name?: string;
  api_url?: string;
  token?: string;
  information?: string;
  icon?: string;
  isselected : boolean = false ;
  stores ? : Store [] = []

  constructor(id: number, attributes: any) {
    this.id = id;
    this.name = attributes.name;
    this.api_url = attributes.api_url;
    this.information = attributes.information;
    this.icon = 'https://connecteur.devsty-it.com' + attributes.icon?.data?.attributes?.formats.small.url;
  }
  static mapSocietes(data: any[]): Societe[] {
    return data.map((item, index) => new Societe(index + 1, item.attributes));
  }

}
export class Store {
  id: number;
  name: string;
  code: string;


  constructor(id: number, attributes: any) {
    this.id = id;
    this.name = attributes.name;
    this.code = attributes.code;
  }

  static mapSocietes(data: any[]): Store[] {
    return data.map((item, index) => new Store(item.id, item.attributes));
  }
}
