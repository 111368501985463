import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import {map, catchError, switchMap, finalize } from 'rxjs/operators';
import { Observable, defer, throwError } from 'rxjs';
import { AuthService } from './demo/service/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  constructor(private authService: AuthService,private router:Router) {}
  loading = true; 
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   /* return defer(() => {
      const key = req.urlWithParams;
      this.loading = true; // Set loading to true before making the request

      console.time(key);
      return next.handle(req).pipe(finalize(() => {
        console.timeEnd(key);
              this.loading = false; // Set loading to false after receiving the response

      }));
    });*/
    req = req.clone({
      withCredentials: true,
    });
    
    const token = this.authService.getAuthorizationToken();
    const isApiUrlStarted = req.url.startsWith(environment.url+"/auth")||req.url.startsWith(environment.url+"/token");
       if (token && !isApiUrlStarted) {
      console.log("test1")
      req = req.clone({
        setHeaders: {Authorization: `Bearer ${token}` },
      });
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (
         // error instanceof HttpErrorResponse &&
          !req.url.includes('/login') &&
          (error.status === 401 || error.status === 403)
        ) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    );
  
  
  
    }
  
  
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
      console.log(this.authService.isLoggedIn())
  
        if (this.authService.isLoggedIn()) {
           return this.authService.refreshToken() 
           /* .subscribe(data=>{
            localStorage.setItem("token", data!.jwt.toString());
            localStorage.setItem("refreshToken", data.refreshToken.toString());
            request = request.clone({
              setHeaders: {Authorization: `Bearer ${data!.jwt.toString()}` },
            });
            request.headers.append('Authorization', `Bearer ${this.authService.getAuthorizationToken()}`)
            console.log(this.authService.getAuthorizationToken())

          },error=>{
            this.router.navigate(['/login']);
          })  ;*/
          .pipe(
            switchMap(token => {
              localStorage.setItem("token", token!.jwt.toString());
              localStorage.setItem("refreshToken", token.refreshToken.toString());
                const cloned = request.clone({
                   headers: request.headers.set("Authorization", "Bearer " + token.jwt)
                });
                return next.handle(cloned);
            }),
            catchError((error) => {
              this.isRefreshing = false;
  
              
                this.router.navigate(['/login']);
               
  
              return throwError(() => error);
            })
          );
        }
      return next.handle(request
      /*  .clone({
        setHeaders: {Authorization: `Bearer ${this.authService.getAuthorizationToken()}` },
      })
      */).pipe(
        catchError((error) => {
        
  this.router.navigate(['/login']);
          return throwError(() => error);
        })
      );
    }

  }

