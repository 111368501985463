export class Fournisseur {
    id: number;
    name: string;
    code: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.attributes.name;
    this.code = data.attributes.code;
   }
   static mapToFournisseurArray(data: any[]): Fournisseur[] {
    return data.map(item => new Fournisseur(item));
  }

}
