export class Achat {

    id: number;
    naturepiece?: string;
    numero?: number;
    souche?: string;
    datepiece?: number;
    etablisssement?: string;
    code_article?: string;
    code_a_barre?: string;
    fournisseur?: string;
    dpa?: number;
    total_quantity?: string;
    quantity : number  ;
    numordre : number  ;
    prix_ht : number ;
    selected : boolean = false ;
    editMode : boolean = false ;
  
    constructor(id: number, attributes: any) {
      this.id = id;
      this.naturepiece = attributes.naturepiece;
      this.numero = attributes.numero;
      this.souche = attributes.souche;
      this.datepiece = attributes.datepiece;
      this.etablisssement = attributes.etablisssement;
      this.code_article = attributes.code_article;
      this.code_a_barre = attributes.code_a_barre;
      this.fournisseur = attributes.fournisseur;
      this.dpa = attributes.dpa;
      this.total_quantity = attributes.total_quantity;
      this.prix_ht = parseFloat(attributes.prix_ht.toFixed(2));
      this.quantity = attributes.quantity;
      this.numordre = attributes.numordre;
    }
  
    static mapAchat(data: any[]): Achat[] {
      return data.map((item, index) => new Achat(item.id, item.attributes));
    }
  }
  
