import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundToThreeDecimals'
})
export class RoundToThreeDecimalsPipe implements PipeTransform {

  transform(value: number): number {
    return Math.round(value * 1000) / 1000;
  }
}

