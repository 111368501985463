import { Component, OnInit , Output, EventEmitter } from '@angular/core';
import {GetFromStrapiService} from '../../Services/GetStrapi/GetFromStrapi.service' ;
import {RANDOMAPIService} from '../../Services/RandomAPI/RANDOMAPI.service' ;
import { Vente } from '../../Models/Ventes';
import { Societe } from '../../Models/Societe';
import { Code } from '../../Models/Generated';
import { Store } from '../../Models/Societe';
import { Societes } from '../../Models/Generated';
import { cpuUsage } from 'process';
import {MessageService} from 'primeng/api';
import { Achat } from '../../Models/Achats';

interface Barcode {
  name? : string
  qte : number 
}
@Component({
  selector: 'app-distribution-dialog',
  templateUrl: './distribution-dialog.component.html',
  styleUrls: ['./distribution-dialog.component.scss']
})
export class DistributionDialogComponent implements OnInit {
  visible = false // pour la visibilité du dilaog
  select : boolean = false
  //-------------------------------  Initialisation et constructor --------------------------------//

   async ngOnInit() {
    await this.getsociete()
   // const foundIndex = this.Societes.findIndex(societe => societe.stores.findIndex(store => store.code === this.DefaultStore));

    this.selectedSocietes = this.Societes
    this.generateSociete()
  }

  constructor(private messageService: MessageService,private Strapi : GetFromStrapiService , private random : RANDOMAPIService) { }
  @Output() dialogClose: EventEmitter<any> = new EventEmitter();
  //---------------------------------------- Societe -------------------------------------//

 
  async getsociete(){
      let found
      let foundIndex = -1 ;
      try {
    const data = await this.Strapi.getFromStrapi("societes" ,1,25,null,null,null,null,null,null,'populate=icon');
    this.Societes = Societe.mapSocietes(data);
    for (const Societe1 of this.Societes) {
        const url = Societe1.api_url
        const data =  await this.random.getUrl(`https://${url}stores?`)
        Societe1.stores = Store.mapSocietes(data)
        if ( Societe1.stores.find(store => store.code === this.DefaultStore)){
         this.Societes = [Societe1];
            break;
        }
    }
} catch (error) {
    console.error('Error fetching ventes:', error);
} finally {
    console.log('DefaultStore' , this.DefaultStore)
    console.log('Defaultsociete' , found)
 
}
      
  }

  DeleteCode(societe , code: Code){
    const index = this.Ste.indexOf(societe)
     this.Ste[index].codes.splice(this.Ste[0].codes.indexOf(code),1)
     this.ids.forEach((element , index) => {
      if(element.code === code.code){
        this.ids.splice(index,1)
      }
     });
   }

  canclick():boolean{
  const index =   this.Ste.find(societe => societe.codes.find(code => code.avaible === false))
    if(index){
       return false
    }else{
      return true
    }
  }

  selectedstore 
 
  async generateSociete() {
    this.Ste = [];
    this.selectedSocietes.forEach((societe)=>{
      let listcode : Code [] = [];
      if(this.type === 'vente'){
        this.barcodesV.forEach(async (cag)=>{
          let check = true ;
          const url = societe.api_url
          console.log('caffffffffffffffffffffff' , cag)

          await this.random.getUrl(`https://${url}products?filters[$and][0][code_article_generique][$eq]=${cag.code_article_generic}`).then((data) => {check = data.length > 0 }) 
        
          // const check :Boolean = data.pagination.total > 0
          const code1 = new Code(cag.code_article_generic , cag.totalttc , cag.qty , cag.qty , check)
          console.log('the code is ',code1)
          listcode.push(code1)
        })
      }else{
        this.barcodeA.forEach((cag)=>{
          const code1 = new Code(cag.code_article , 100 , 0 , cag.quantity)
          listcode.push(code1)
        })
      }
      console.log('societe:', societe);
      setTimeout(() => {
        console.log('societe.stores:', societe.stores);
        const storeindex = societe.stores.findIndex(store => store.code === this.DefaultStore)
        console.log('la index est = ', storeindex)
        this.selectedstore = societe.stores[storeindex]
        const societe1 = new Societes(societe.id,societe.name,listcode ,societe.stores[storeindex].code)
        console.log('la societe est = ',societe1)
        this.Ste.push(societe1)
      }, 500);
      
    })
  }

  // --------------------------- Parametres des tableau et changement de list -------------//

  async getstore(societe : Societe) :  Promise <Store[]>  {
    const url = societe.api_url
    const data =  await this.random.getUrl(`https://${url}stores?`)
    const stores = Store.mapSocietes(data)
    return stores
  }

  isselected(societe) : boolean{
    const find = this.selectedSocietes.filter((ste) => ste.id === societe.id ).length > 0
    return find
  }

  onRowSelect(event) {
    this.generateSociete()
  }

  onStoreSelect(event,societe){
    this.DefaultStore = event.data.name
   const find = this.selectedSocietes.filter((ste) => ste.id === societe.id ).length > 0
    if (find) {
      this.Ste.forEach((soc)=>{
        if(soc.name === societe.name){
          soc.store = event.data.code
        }
      })
    } else {

    }
  }

  onRowUnselect(event) {
    this.generateSociete()
  }

   onQtyChange( codeAr: Code) : number {
    let sum = 0;
    this.Ste.forEach((societe) => {
      societe.codes.forEach((cod) => {
        if (cod.code === codeAr.code && cod.prix === codeAr.prix) {
          sum += cod.qty;
        }
      });
    });
    return codeAr.qtytotal - sum  
  }

  check():void {
    console.log(this.Ste)
    const  find  = this.Ste.filter((st)=> st.store === undefined )
    if(find.length>0 ){
      this.messageService.add({severity:'error', summary:'Depot Vide', detail:'Selectionner le depot'});
      setTimeout(() => {
        this.messageService.clear();
      },2000);
    }else{
      if(this.Ste.length === 0){
        this.messageService.add({severity:'error', summary:'Groupe Vide', detail:'Selectionner un groupe'});
        setTimeout(() => {
          this.messageService.clear();
        },2000);
     
      }else{
        const filteredIds: number[] = this.ids
        .filter(code => code)
        .map(({ id }) => id);
       const filteredCodes = this.Ste[0].codes.filter(code => code.avaible).map(({ avaible, ...rest }) => rest);
       const data = {
          "id": this.Ste[0].id,
          "ids" : filteredIds,
          "name": this.Ste[0].name,
          "codes":filteredCodes,
          "store": this.Ste[0].store
        }
        console.log('mydata is : ',data)
        this.Strapi.postStrapi('manageVente',[data])
        this.visible = false
        this.dialogClose.emit();
      }
    }
  }

  //----------------------------- show & close ------------------------//
  DefaultStore
  barcodesV: Vente[] = [];  // Pour les Code depuis le tableau 
  barcodeA : Achat[] = [];  // Pour les Code depuis le tableau 
  Societes: Societe[] = []; // Pour les societes depuis Strapi 
  selectedSocietes: Societe[] = []; // pour les Societe selectionner 
  ids : any[] = []; // pour les ids des codes 
  Ste :Societes [] =  []; // la liste des societes avec des codes articles a afficher sure le tableau 
  type : string 
  showdialog(vente? : Vente[] , achat? : Achat[] , Lids? : any[]): void { 
    this.ids = Lids 
    this.selectedSocietes = [] 
    this.Ste = [] 
    this.barcodesV = [] 
    this.barcodeA = [] 
    this.Societes = [] 
    this.visible = true ; 
    if(vente){ this.type = 'vente' ; this.barcodesV = vente ; this.DefaultStore = vente[0].souche} else { this.type = 'achat' ; this.barcodeA = achat ; this.DefaultStore = achat[0].souche} 
    this.ngOnInit()
  }
  close(): void {
    this.visible = false;
  }

  //--------------------------------------------------------------------//
}
