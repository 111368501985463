export class Code {
  code: string;
  prix: number;
  qty: number;
  qtytotal: number;
  avaible ? : boolean

  constructor(code: string, prix: number, qty: number, qtytotal: number = 0 , avaible? : boolean) {
    
    this.code = code;
    this.prix = prix;
    this.qty = qty;
    this.qtytotal = qtytotal;
    this.avaible = avaible
  }
}

export class Societes {
  id : number;
  name: string;
  codes: Code[];
  store? : string; 
  

  constructor(id : number , name: string, codes: Code[], store ? : string) {
    this.id = id
    this.name = name;
    this.codes = codes;
    this.store = store
  }

  
}
