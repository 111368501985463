import { Component, OnInit , ViewChild  } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import {GetFromStrapiService} from '../../../Services/GetStrapi/GetFromStrapi.service' ;
import {Achat} from '../../../Models/Achats' ;
import {DistributionDialogAchatComponent} from '../../../Pages/distribution-dialog-achat/distribution-dialog-achat.component';
import {MessageService} from 'primeng/api';
import { Societe } from 'src/app/Facture/Models/Societe';
import {RANDOMAPIService} from '../../../Services/RandomAPI/RANDOMAPI.service' ;
import { Fournisseur } from 'src/app/Facture/Models/Fournisseur';

@Component({
  selector: 'app-consultation-achat',
  templateUrl: './consultation-achat.component.html',
  styleUrls: ['./consultation-achat.component.scss']
})
export class ConsultationAchatComponent implements OnInit {
  @ViewChild('overlayPanel') overlayPanel!: OverlayPanel;
  @ViewChild(DistributionDialogAchatComponent) Dialog: DistributionDialogAchatComponent;
  @ViewChild('paginator') paginator: any;
  //--------------------- Declaration ----------------------------// 

  filter  
  Achats : Achat[] = []
  totalRecords: number;
  page: number = 1;
  size: number = 10;
  sortField: string | undefined;
  sortOrder: number | undefined;
  loading = false ;
  date
  editMode = false
  selectAllCheckbox: boolean = false;
  Societes: Societe[];
  selectedSociete:Societe [];
  fournisseur: Fournisseur[];
  selectedFournisseur: any
  filtredfournisseur: string[] | undefined;
  ids: any[];
  so = []
  fo = []

  // filteredstore: string[] = ["R2", "R9"];
  constructor( private messageService: MessageService , private Strapi : GetFromStrapiService, private random : RANDOMAPIService) { }

 async ngOnInit() {

    this.getAchat();
    try {
      const data = await this.Strapi.getFromStrapi("societes" ,1,25,null,null,null,null,null,null,'populate=icon' );
      this.Societes = Societe.mapSocietes(data);
      console.log('scest',this.Societes)
      this.Societes.forEach(async(selectedSociete) => {
        const url = selectedSociete.api_url
        const data =  await this.random.getUrl(`https://${url}fournisseurs?`)
        const fournisseur =  Fournisseur.mapToFournisseurArray(data)
        this.filtredfournisseur = this.filtredfournisseur.concat(fournisseur.map(f => f.code));
        console.log(this.filtredfournisseur)
        console.log(data)
      })
      this.filtredfournisseur = Array.from(new Set(this.filtredfournisseur));
      console.log('fournisss ',this.filtredfournisseur)
  }
  catch (error) {
    console.error('Error fetching societes:', error);
  }}

  onSocieteSelect(event: any) {
    const selectedSocietes: Societe[] = event.value;
    this.fournisseur = [];
  
    selectedSocietes.forEach(async(selectedSociete) => {
      const url = selectedSociete.api_url
      const data =  await this.random.getUrl(`https://${url}fournisseurs?`)
      this.fournisseur = this.fournisseur.concat( Fournisseur.mapToFournisseurArray(data))
    })
    this.fournisseur = Array.from(new Set(this.fournisseur));
  }

  onAutoCompleteChangefour(event: any) {
    console.log('AutoComplete value changed:', event);
    
    this.Achats.forEach((Achat, index) => {
      console.log(Achat.selected);
      if (Achat.selected) {
        this.Achats[index].fournisseur = event;
      }
    });
  }

  onfournisseurSelect(event){

    this.selectedFournisseur = event.value;
    console.log(this.selectedFournisseur)
 }

 filterfournisseur(event: any) {
  const query = event.query;
  this.filtredfournisseur = this.filtredfournisseur
    .filter(fournisseur => fournisseur.toLowerCase().includes(query.toLowerCase()));
}

 async deletedata(achat: Achat , i : number){
  const id = this.Achats[i].id
  const data = {"data" :{
    "available": false
  }}
  this.loading=true;
 await this.Strapi.updateById('achats',id,data)
 this.loading=false;
  this.getAchat()
 }

  //---------------------- CRUD Achat -------------------------- //

  async getAchat() {
   const datetochange = this.date
      if (datetochange ) {
            datetochange[0].setHours(1);
            datetochange[0].setDate(datetochange[0].getDate() - 1);
            datetochange[0].setDate(datetochange[0].getDate());
            datetochange[1] ?   datetochange[1].setDate(datetochange[1].getDate() - 1): '';
        }
    console.log('date is : ', this.date)
    let  add : string = ''
    if (this.selectedFournisseur){
      this.selectedFournisseur.forEach((selected) => {
        add += `filters[$and][0][fournisseur][$eq]=${selected.name}&`;
      })
    }
   this. loading = true ; 
   try {
    const data = await this.Strapi.getFromStrapi('achats', this.page, this.size , this.sortField , this.sortOrder , this.filter, 'code_article', null, this.date , add + '&filters[available][$eq]=true','datecreation');
    this.Achats = Achat.mapAchat(data);
    this.totalRecords = this.Strapi.total
  } catch (error) {
    console.error('Error fetching ventes:', error);
  } finally {
    this.loading = false;
  }
  console.log(this.Achats)
  console.log(this.totalRecords)

  }

  searchAchat(): void {
    this.paginator.changePage(0);
    this.getAchat();
  }

  //-------------------- table dynamic columns --------------------------//

  Cols: any[] = [
    { field: 'naturepiece', header: 'Nature de la pièce' },
    { field: 'numero', header: 'Numéro' },
    { field: 'souche', header: 'Souche' },
    { field: 'datepiece', header: 'Date de la pièce' },
    { field: 'etablisssement', header: 'Établissement' },
    { field: 'code_article', header: 'Code article' },
    { field: 'code_a_barre', header: 'Code à barres' },
    { field: 'fournisseur', header: 'Fournisseur' },
    { field: 'dpa', header: 'DPA' },
    { field: 'total_quantity', header: 'Quantité totale' },
    { field: 'quantity', header: 'Quantité' },
    { field: 'numordre', header: 'Numéro d\'ordre' },
    { field: 'prix_ht', header: 'Prix HT' },
  ];
  
      
  SelectedCols: any[] = [
    this.Cols[0],
    this.Cols[5],
    this.Cols[7],
    this.Cols[10],
    this.Cols[12],
    this.Cols[3],

  ];

  showOverlayPanel(event: Event) {
    this.overlayPanel.show(event);
  }

  //-------------------- paginator sort  --------------------------//

  onLazyLoad(event: LazyLoadEvent): void {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.paginator.changePage(0);
    this.getAchat();
    }

  get totalPages(): number {
    return Math.ceil(this.totalRecords / this.size);
  }

  onPageChange(event: any): void {
    this.page = event.page + 1;
    this.size = event.rows;
    this.getAchat();
  }

  filtrer() {
    this.paginator.changePage(0);
    this.getAchat()
  }

  //--------------------------- Edit mode ----------------------//
filterStore(event: any) {
  // const query = event.query;
  // this.filteredstore = this.filteredstore
  //   .filter(store => store.toLowerCase().includes(query.toLowerCase()));
}
shouldDisablePen(achat: any): boolean {
  return achat.selected && !this.isFirstSelected(achat);
}
handlePenClick(achat: Achat,i : number): void {
  this.Achats[i].editMode = ! this.Achats[i].editMode
  this.Achats.forEach((achat, i) => {
    if (achat.selected) {
      this.Achats[i].editMode = true ;
    }
  });
}
editmodeopen():boolean{
  return this.Achats.some(achat => achat.editMode === true);
}
onAutoCompleteChange(event: any) {
  console.log('AutoComplete value changed:', event);
  
  this.Achats.forEach((achat, index) => {
    console.log(achat.selected);
    if (achat.selected) {
      this.Achats[index].souche = event;
    }
  });
  }
saveChanges(achat: Achat ): void {
  console.log('achat', achat);

  this.Achats.forEach((achat, index) => {
    console.log(achat.selected);
    if (achat.selected) {
    }
    this.Achats[index].editMode = false ; 
  });
}
cancelEditing(achat: Achat): void {
  this.Achats.forEach((Achat, index) => {
    Achat.editMode = false
  });
}
isFirstSelected(achat: any): boolean {
  const firstSelectedIndex = this.Achats.findIndex(item => item.selected);
  return firstSelectedIndex === -1 || this.Achats.indexOf(achat) === firstSelectedIndex;
}
getVenteFieldValue(index: number, fieldName: string): any {
  const achat = this.Achats[index];
  return achat ? achat[fieldName] : null;
}

reset(){
  this.so = []
  this.fo = []
  this.selectedFournisseur = []
  this.date = undefined ;
  this.paginator.changePage(0);
  this.getAchat();
}

// async senddata(Achat: Achat , i : number){
//   let ids = []
//   this.Achats[i].selected = true ;
//   const foundIndex = this.Societes.findIndex(societe => societe.stores.findIndex(store => store.name === Achat.souche));
//   const foundIndexstore = this.Societes[foundIndex].stores.findIndex(store => store.name === Achat.souche);
//   console.log('index is ' , foundIndex)
//   let datacode = [];
//   this.generatedList = []; 
//   this.Achats.forEach((vent) => {
//     console.log(vent.selected);
//     if (vent.selected) {
//       ids.push(vent.id);
//         const existingItem = this.generatedList.find((item) => item.code_article_generic === vent.code_article && item.prixttc === vent.prix_ht);  
//         if (existingItem) {
//           existingItem.qty += vent.quantity || 0;
//         } else {
//           this.generatedList.push({ ...vent });
//         }
//     } 
//   });

//   if (foundIndex !=1 && foundIndexstore != -1){
//     const uniqueSouches = Array.from(new Set(this.generatedList.map(vente => vente.souche)));
//     if(uniqueSouches.length>1){
//       this.messageService.add({severity:'error', summary:'Depot', detail:'Selectionner le meme depot ou modifier'});
//       setTimeout(() => {
//         this.messageService.clear();
//       },4000);
//     }else{
//       this.generatedList.forEach((vent, i) => {
//         const  data = {"code": vent.code_article_generic,
//         "prix": vent.prixttc,
//         "qty": vent.qty,
//         "qtytotal": vent.qty}
//         datacode.push(data)
//        }) 
       
//        const data = [{ "id": this.Societes[foundIndex].id,
//        "ids" : ids,
//        "name": this.Societes[foundIndex].name,
//        "codes": datacode , 
//        "store": this.Societes[foundIndex].stores[foundIndexstore].code
//      }]
//      this.loading = true;
//      await this.Strapi.postStrapi('manageVente',data)
//      this.loading = false;
//     }
//   }else{
//     this.messageService.add({severity:'error', summary:'Depot', detail:'Depot non trouvable dans votre liste de groupes'});
//       setTimeout(() => {
//         this.messageService.clear();
//       },4000);
//   }
// }

//--------------------------- CheckedBox ----------------------//

onSelectAllCheckboxChange() {
  this.Achats.forEach((achat) => (achat.selected = this.selectAllCheckbox));
}

isAnyClientSelected(): boolean {
  return !this.Achats || this.Achats.every(client => !client.selected);
}

generatedList
showdialog() {
  this.ids = [];
  this.generatedList = []; 
  this.Achats.forEach((Achat) => {
    console.log(Achat.selected);
    if (Achat.selected) {
      this.ids.push(Achat.id);
        const existingItem = this.generatedList.find((item) => item.code_article === Achat.code_article );  
        if (existingItem) {
          existingItem.quantity += Achat.quantity || 0;
        } else {
          this.generatedList.push({ ...Achat });
        }
    } 
  });
  console.log(this.generatedList)
  if (this.generatedList.length > 0 ){
    const uniqueSouches = Array.from(new Set(this.generatedList.map(Achat => Achat.fournisseur)));
    if(uniqueSouches.length>1){
      this.messageService.add({severity:'error', summary:'Fournisseur', detail:'Selectionner le meme fournisseur ou modifier'});
      setTimeout(() => {
        this.messageService.clear();
      },4000);
    }else{
      this.Dialog.showdialog(this.generatedList , this.ids)
    }}else{
      this.messageService.add({severity:'error', summary:'Vide', detail:'Selectionner un code article'});
    setTimeout(() => {
      this.messageService.clear();
    },4000);  
  }

}

}
