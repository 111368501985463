import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {MessageService} from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class RANDOMAPIService {

  constructor(private http: HttpClient, private messageService: MessageService) { }

  async getUrl ( url1 : string ) : Promise<any[]> {
    console.log('urlllll' , url1)  
    try {
      const apiResponse: any = await this.http.get(url1).toPromise();
      if (apiResponse && apiResponse.data) {
        return apiResponse.data;
      } else {
        // Handle error response
      //  console.error('Error in API response:', apiResponse);
    
        // Check for status code in case of an error
        if (apiResponse && apiResponse.status) {
          // Assuming the error message is in the response body
         // const errorMessage = apiResponse.error?.message || 'Une erreur s\'est produite lors de la récupération des données.';
          
        //   this.messageService.add({
        //     severity: 'error',
        //     summary: 'Erreur de récupération',
        //     detail: errorMessage,
        //   });
        }
    
        throw new Error('Error in API response');
      }
    } catch (error) {
      // Handle error and display an error message
      console.error('Error fetching clients:', error);
    
      let errorMessage = 'Une erreur s\'est produite lors de la récupération des données.';
      
      if (error && error.error && error.error.message) {
        errorMessage = error.error.message;
      }
    
    //   this.messageService.add({
    //     severity: 'error',
    //     summary: 'Erreur de récupération',
    //     detail: errorMessage,
    //   });
    
      throw error;
    }
  }  

}
