
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
//import { Observable, throwError } from "rxjs";
import { Observable, of, Subject } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) { }

  /*erroHandler(error: HttpErrorResponse) {
    if (error.status === 404) return throwError(() => new Error("error 404"));
  }*/
 

  login(credentials): Observable<any> {
    const options = {
      credentials: "include",
      withCredentials: true,
    };
    return this.http.post<any>(environment.url+"/auth/local" , credentials,options);
  }
  getAuthorizationToken() {
    return localStorage.getItem("token");
  }
  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }


   refreshToken() {
    const options = {
      "Access-Control-Allow-Credentials": true,
      withCredentials: true,
    };
    //let urls="https://prefacture.rosace.com.tn/api/token/refresh"
    return this.http.post<any>(environment.url+"/token/refresh" , ({
      "refreshToken":this.getRefreshToken()
      }),options);
  }
  
  isLoggedIn() {
    console.log("testS")
    if (!this.getRefreshToken()) return false;
    return !new JwtHelperService().isTokenExpired(this.getRefreshToken());
  }
  
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }

}
