import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appColumnFilterApply]'
})
export class ColumnFilterApplyDirective {
 @Output() appColumnFilterApply: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    // Emit the event when the "Apply" button is clicked
    console.log(event)
    this.appColumnFilterApply.emit();
  }
}