import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frenchNumber'
})
export class FrenchNumberPipe implements PipeTransform {
  transform(value: number): string {
    return this.convertToFrenchWords(value);
  }

  private convertToFrenchWords(value: number): string {
    if (value === undefined || isNaN(value)) {
      return 'Indéfini'; // Handle undefined or NaN values
    }
  
    // Handle commas in the value
    const parts = value.toString().split('.');
    const integerPart = parseInt(parts[0], 10);
  
    let words = this.toWords(integerPart);
  
    if (parts.length === 2 && parseInt(parts[1], 10) > 0) {
      words += ' virgule ' + this.toWords(parseInt(parts[1], 10));
    }
  
    return words + " dinar";
  }

  private toWords(value: number): string {
    const units = ['', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf'];
    const teens = ['dix', 'onze', 'douze', 'treize', 'quatorze', 'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf'];
    const tens = ['', '', 'vingt', 'trente', 'quarante', 'cinquante', 'soixante', 'soixante-dix', 'quatre-vingt', 'quatre-vingt-dix'];
  
    if (value === 0) {
      return 'zéro';
    }
  
    if (value < 0) {
      return 'moins ' + this.toWords(Math.abs(value));
    }
  
    let words = '';
  
    if (Math.floor(value / 1000) > 0) {
      words += this.toWords(Math.floor(value / 1000)) + ' mille ';
      value %= 1000;
    }
  
    if (Math.floor(value / 100) > 0) {
      words += this.toWords(Math.floor(value / 100)) + ' cent ';
      value %= 100;
    }
  
    if (value > 0) {
      if (words !== '') {
        words += 'et ';
      }
  
      if (value < 10) {
        words += units[value];
      } else if (value < 20) {
        words += teens[value - 10];
      } else {
        words += tens[Math.floor(value / 10)];
        const remainder = value % 10;
        if (remainder > 0) {
          words += '-' + units[remainder];
        }
      }
    }
  
    return words.trim() + " " ;
  }
   
}
