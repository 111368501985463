<div class="grid">
	<div class="col-12">
		<div class="card">

			<!--------------------Consultation Tickets PAGE---------------------->

            <div style="display: flex; justify-content: space-between; align-items: center; padding-bottom : 10px;">
                <h5>Consultation Achat</h5>
                <p-button (click)="showOverlayPanel($event)">
                    <ng-template pTemplate="icon">
                      <i class="fas fa-cog fa-2x"  style="color: white;"></i>
                    </ng-template>
                  </p-button>
              </div>

              <p-overlayPanel #overlayPanel [dismissable]="true" [showCloseIcon]="true">
                <!-- Content for the overlay panel -->
                <p-multiSelect
                  [options]="Cols"
                  [(ngModel)]="SelectedCols"
                  optionLabel="header"
                  [filter]="true"
                  [style]="{ 'max-width': '150px', 'min-width': '150px' }"
                ></p-multiSelect>
              </p-overlayPanel>

    <!--------------------Tableau Client ---------------------->


            <p-table
            [paginator]="true"
            class="fadeinleft animation-duration-1000   border-round m-2 px-5 py-3"
            [value]="Achats"
            [columns]="SelectedCols"
            [tableStyle]="{'min-width': '60rem'}"
            [rows]="10"
            sortMode="single"
            responsiveLayout="stack"
            scrollHeight="flex"
            [totalRecords]="totalRecords"
            [showCurrentPageReport]="true"
            dataKey="id"
            [paginator]="false"
            [lazy]="true"
            [loading]="loading"
            (onLazyLoad)="onLazyLoad($event)"
            >

                  <!-------------------- Buttons et recherche ---------------------->

                 <ng-template pTemplate="caption" style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;">
                    <!-- <button
                    pButton
                    pRipple type="button"
                    icon="pi pi-trash"label="Supprimer "
                    class="p-button-danger mr-2 mb-2 "
                    style="width: 15px;  background-color: #EF4444;"
                    (click)="deleteClient()"
                    [disabled]="isAnyClientSelected()">
                    </button> -->
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;">
                      <p-multiSelect
                      [options]="Societes"
                      placeholder="Societes"
                      optionLabel="name"
                      [filter]="true"
                      [style]="{ 'margin-right': '5px', 'margin-bottom': '5px', 'max-width': '250px', 'min-width': '150px' }"
                      (onChange)="onSocieteSelect($event)"
                        [(ngModel)]="so"
                    ></p-multiSelect>
                    
                    <p-multiSelect
                    [options]="fournisseur"
                    placeholder="Fournisseur"
                    optionLabel="name"
                    [filter]="true"
                    [style]="{ 'margin-right': '5px', 'margin-bottom': '5px', 'max-width': '250px', 'min-width': '150px' }"
                    (onChange)="onfournisseurSelect($event)"
                     [(ngModel)]="fo"
                    ></p-multiSelect>
    

                    <!-- <p-multiSelect
                 
                    [options]="Clients"
                
                    placeholder="Client"
                    optionLabel="name"
                    [filter]="true"
                    [style]="{ 'margin-right': '5px' , 'margin-bottom': '5px',  'max-width' : '150px' ,'min-width' : '150px'}">
                    </p-multiSelect>
                    <p-multiSelect
                    [options]="articles"
                    placeholder="Article"
                    optionLabel="code_article_generique"
                    [filter]="true"
                    [style]="{ 'margin-right': '5px' , 'margin-bottom': '5px' , 'max-width' : '150px','min-width' : '150px'}">
                    </p-multiSelect> -->

                    <p-calendar
                    [(ngModel)]="date"
                  
                    selectionMode="range"
                    placeholder="Date"
                    [readonlyInput]="true"
                    style="margin-right: 5px ; margin-bottom: 5px;">
                    </p-calendar>
   
                   <button pButton pRipple type="button" 
                           icon="fa fa-filter bi bi-filter" 
                           label="Filtrer" 
                           class="p-button-success mr-0.5 mb-"
                           style="margin-right: 5px;"
                           (click)="filtrer()"
                           >
                   </button>
   
                   <button pButton pRipple type="button" 
                   (click)="reset()"
                               icon="bi bi-arrow-clockwise" 
                               label="Refresher" 
                               class="btn btn-primary mr-0.5 mb-" 
                               style="margin-right: 4px;  background-color: rgba(0, 78, 156, 0.158); color: black;"
                   >
                   </button>
                    
                </div> 
    
               </ng-template>

               <!-------------------- Columns ---------------------->

               <ng-template pTemplate="header" let-columns>

                <tr>
                  <th *ngIf="!editmodeopen()" style="width: 2% ; align-items: center;">
                    <div *ngIf="!editmodeopen()" class="field-checkbox">
                      <p-checkbox [binary]="true" [(ngModel)]="selectAllCheckbox"
                      (onChange)="onSelectAllCheckboxChange()"></p-checkbox>
                    </div>
                  </th>
                  <th *ngFor="let col of columns" pSortableColumn="{{ col.field }}">
                    {{ col.header }}
                    <p-sortIcon field="{{ col.field }}"></p-sortIcon>
                  </th>
                  <th style="width: 15%">
                    <span class="p-input-icon-left mb-2">
                      <i class="pi pi-search"></i>
                      <input
                        pInputText
                        type="text"
                        [(ngModel)]="filter"
                        (input)="searchAchat()"
                        placeholder="Rechercher"
                        class="w-full"
                        style="height: 35px;"
                      />
                      <span class="p-input-icon-right mb-2" (click)="clearFilter()">
                        <i class="pi pi-times" ></i>
                      </span>
                    </span> 
                  </th>
                </tr>
              </ng-template>

              <!------------------------------------------------>

               <ng-template pTemplate="body" let-achat let-rowIndex="rowIndex">
                <tr [ngClass]="{'bg-bluegray-200': shouldDisablePen(achat)}" >

                  <!-------------------- check items   ---------------------->

                  <td *ngIf="!editmodeopen()">
                    <div  class="field-checkbox">
                      <p-checkbox [binary]="true" [(ngModel)]="achat.selected"></p-checkbox>
                    </div>
                  </td>
                  <td *ngFor="let col of SelectedCols">

                    <!-------------------- Display mode  ---------------------->

                    <ng-container *ngIf="!achat.editMode; else cellEditor">
                      {{ achat[col.field] }}
                    </ng-container>

                    <!-------------------- Edit mode  ---------------------->

                    <ng-template #cellEditor>
                      <ng-container *ngIf="col.field === 'fournisseur' else numberInput">
                        <!-- Render p-multiselect for souche -->
                        <p-autoComplete
                        [(ngModel)]="achat[col.field]"
                        [dropdown]="true"
                        [suggestions]="filtredfournisseur"
                        (completeMethod)="filterfournisseur($event)"
                        (ngModelChange)="onAutoCompleteChangefour($event)"
                        ></p-autoComplete>

                      </ng-container>
                      <ng-template #numberInput>
                        <!-- Render number input for qty, prixttc, totalttc -->
                        <p-inputNumber [disabled]="shouldDisablePen(achat)" [(ngModel)]="achat[col.field]" name="{{ col.field }}" *ngIf="col.field === 'qty' "></p-inputNumber>
                        <p-inputNumber [disabled]="shouldDisablePen(achat)" [(ngModel)]="achat[col.field]" name="{{ col.field }}" *ngIf="col.field ===  'prixttc'"></p-inputNumber>
                        <p-inputNumber [disabled]="shouldDisablePen(achat)" [(ngModel)]="achat[col.field]" name="{{ col.field }}" *ngIf="col.field === 'totalttc' "></p-inputNumber>
                        <!-- For other numeric fields, display them without an input -->
                        <span *ngIf="col.field !== 'qty' && col.field !== 'prixttc' && col.field !== 'totalttc'">{{ achat[col.field] }}</span>
                      </ng-template>
                    </ng-template>
                  </td>
                  <td  class="flex flex-wrap align-items-center justify-content-center" >

                    <!-- ------------------ Action icon  -------------------- -->

                    <ng-container *ngIf="!achat.editMode; else editActions">
                      <p-button *ngIf="!editmodeopen()" class="pr-2"  icon="pi pi-pencil"  (click)="handlePenClick(achat , rowIndex)"></p-button>
                      <!-- <p-button *ngIf="!editmodeopen()" icon="bi bi-send"  severity="secondary" class="pr-2"  (click)="senddata(achat ,rowIndex)"></p-button> -->
                      <p-button *ngIf="!editmodeopen()" icon="pi pi-times"  severity="danger" (click)="deletedata(achat ,rowIndex)"></p-button>
                    </ng-container>

                  <!-------------------- Action icon apres modification ---------------------->

                    <ng-template #editActions>
                      <p-button  *ngIf="!shouldDisablePen(achat)" icon="pi pi-check" class="pr-4" (click)="saveChanges(achat)" ></p-button>
                      <p-button  *ngIf="!shouldDisablePen(achat)" icon="pi pi-times" (click)="cancelEditing(achat)"></p-button>
                    </ng-template>
                  </td>
                </tr>
              </ng-template>

               <!-------------------- footer Paginator and gererate button  ---------------------->
              
              <ng-template pTemplate="summary">
                <div class="flex flex-row-reverse flex-wrap">
                  <p-button
                    (click)="showdialog()"
                    icon="bi bi-send"
                    label="Generer"
                    size="large"
                    class="p-button-success mr-0.5 mb- pl-8 flex-shrink-1 flex align-items-center justify-content-center bigger-button"
                  ></p-button>
                  <p-paginator  #paginator
                    [totalRecords]="totalRecords"
                    [rows]="size"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [pageLinkSize]="5"
                    [alwaysShow]="false"
                    (onPageChange)="onPageChange($event)"
                    class="paginator-no-background"
                  ></p-paginator>
                </div>
              </ng-template>              
            </p-table> 

        </div>
        <!------------------------------------------------------------------------------------------------------------------------>
        <app-distribution-dialog-achat></app-distribution-dialog-achat>
        <p-toast></p-toast>
</div>
</div>
