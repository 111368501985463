import { Component,OnInit } from '@angular/core';
import { FormBuilder,  FormControl,  FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../demo/service/auth.service';
import { of } from 'rxjs';
import { Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { MessageService } from 'primeng/api';
import { NgZone } from '@angular/core';
import { ToastModule } from 'primeng/toast';
@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  
})
export class AppLoginComponent implements   OnInit {
p
  dark: boolean;

  checked: boolean;
  invalidLogin: boolean;

  constructor( private ngZone: NgZone,   private router: Router,
             private service: AuthService,
             private messageService: MessageService
             ){
 
  }
  ngOnInit() {
    //this.signIn({identifier:"mohamed.abdelkefi@rosace.com.tn",  password:'ninonino95'})
  //  this.getAuthorizationToken()
   // console.log("fff",this.p) 
}

  form = new FormGroup({
    identifier: new FormControl("", [
      Validators.required,
      Validators.minLength(5),
    ]),
    password: new FormControl("", [

      Validators.required,
      Validators.minLength(10),
    ]),
  });
 







  signIn(credentials) {
   // console.log("credentials",credentials)
    this.service.login(credentials).subscribe(
      (result) => {
        if (result) {
          localStorage.setItem("token", result.jwt.toString());
          localStorage.setItem("refreshToken", result.refreshToken.toString());
          this.service.refreshToken().subscribe(data=>{
            console.log(data)
          })
          console.log('result', result 
            )
        
          /*this.userService.setCurrentUser();
          this.userService.setCurrentUserRole();
          this.userService.setCurrentUserStores();*/
          this.router.navigate(["/"]);
        
        } else this.invalidLogin = true;
      },
      (err) => {
        console.log(err)
 
        this.messageService.add({key: 'toast1', severity:  'error', summary: 'error', detail: 'error' });
     
      }
    );
  }


  get identifier() {
    
    return this.form.get("identifier");
   }
   get  password() {
     
     return this.form.get("password");
     
    }
   
   
  ///  getAuthorizationToken() {
   //   return  this.p=localStorage.getItem("token");
      
  //  }
     
}
