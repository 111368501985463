import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient) { }

  async getConfig() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/configuration`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
 async me() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/users/me`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
         .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
