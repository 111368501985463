
<p-dialog header="DIALOG" [(visible)]="visible" [modal]="true" (onHide)="close()"  [style]="{ width: '95rem', 'max-width': '109rem' }"
  [maximizable]="true" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <!-- <p-avatar image="https://primefaces.org/cdn/primeng/images/avatar/amyelsner.png" shape="circle" /> -->
      <span class="font-bold white-space-nowrap">Connecteur</span>
    </div>
  </ng-template>
  <div class="flex flex-column  md:flex-row">
    <!-- (completeMethod)="filterBrands($event)" -->
    <!--------------------------------------- 1er Partie ---------------------------------------------->

    
                                            
    <div  class="m-2 flex-column    align-items-center gap-4">
    <h4>Store est {{DefaultStore}}  </h4>
    
    <h5 class="font-bold mt-5" >choisir la liste des groupes</h5>
    <p-table
    [(selection)]="selectedSocietes"
    dataKey="id"
    selectionMode="multiple"
    [value]="Societes"
    responsiveLayout="stack"
    [breakpoint]="'960px'"
    [style]="{ 'max-width': '100%' }"
    [tableStyle]="{ 'width': '100%' }"
    styleClass="p-datatable-gridlines mt-5"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)"
    >
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="font-bold">Groupes</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-societe let-rowIndex="rowIndex" let-expanded="expanded">
    <tr [pSelectableRow]="societe" [pSelectableRowIndex]="rowIndex">
      <td *ngIf="type === 'vente'">
        <button  type="button" pButton pRipple [pRowToggler]="societe" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td class="font-bold">
        <div class="flex flex-wrap p-2 align-items-center gap-4">
          <img *ngIf="societe.icon" [src]="societe.icon" alt="Product Image" class="m-2 w-3rem shadow-3 flex-shrink-0 border-round" />
          <div class="flex-1 flex flex-column gap-2">
            {{ societe.name + " ( " + societe.information + " )" }}
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

</div>
 

    <!----------------------------------------- Divider ---------------------------------------->

    <div class="w-full md:w-2">
      <p-divider layout="vertical" styleClass="hidden md:flex"><b>VERS</b></p-divider>
      <p-divider layout="horizontal" styleClass="flex md:hidden" [align]="'center'"><b>OR</b></p-divider>
    </div>

    <!----------------------------------- 2eme Partie ---------------------------------------------->

    <div class="w-full md:w-2 flex align-items py-2">
        <div *ngIf="loading">
            <p-progressSpinner></p-progressSpinner>
          </div>
      <p-table
      *ngIf="!loading"
      responsive="false"
      [value]="Ste[0]?.codes"
      styleClass="p-datatable-gridlines"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="font-bold">Barcode</th>
          <th *ngFor="let societe of Ste" class="font-bold">{{ societe.name }}</th>
          <th class="font-bold">Total</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-code let-columns="columns" let-ri="rowIndex">
        <tr>
          <td class="font-bold">
            {{  code.code }}
          </td>
          <td *ngFor="let societe of Ste">
            <span class="p-column-title">{{ code.qty }}</span>
            <div class="flex justify-content-between flex-wrap" *ngIf="!societe.codes[ri].avaible" style="color: rgb(235, 31, 31);"><p   class="flex justify-content-between flex-wrap font-bold">non disponible</p><p-button class="flex justify-content-between flex-wrap" icon="pi pi-times" [rounded]="true" severity="danger" (click) = 'DeleteCode(societe,code)'></p-button></div>
            <p-inputNumber *ngIf="societe.codes[ri].avaible" [(ngModel)]="societe.codes[ri].qty"  [max]="societe.codes[ri].qtytotal" ></p-inputNumber>
          </td>
          <td>
            <span class="p-column-title">{{ code.qty }}</span>
            <p>{{code.qtytotal}}</p>
          </td>
        </tr>
      </ng-template>
    </p-table>
    </div>
    
    <!--------------------------------------------------------------------------------->

  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="visible = false" label="Anuller" pAutoFocus [autofocus]="true"></p-button>
    <p-button icon="pi pi-check" (click)="check()" label="Ok" pAutoFocus [autofocus]="true" *ngIf="canclick()"></p-button>
  </ng-template>
</p-dialog>